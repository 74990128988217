<template>
  <v-dialog
    v-model="isShow"
    persistent
    :max-width="maxWidth"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="handleConfirmClick"
        >
          {{ confirmText }}
        </v-btn>

        <v-btn
          color="green darken-1"
          text
          @click="handleCancelClick"
        >
          {{ cancelText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RccConfirmDialog',

  props: {
    isShow: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    confirmText: {
      type: String,
      default: 'Подтвердить'
    },

    cancelText: {
      type: String,
      default: 'Отменить'
    },

    confirmCallback: {
      type: Function,
      default: null
    },

    maxWidth: {
      type: String,
      default: '700px'
    }
  },

  methods: {
    handleCancelClick(event) {
      event.stopPropagation()
      this.$emit('update:is-show', false)
    },

    handleConfirmClick(event) {
      event.stopPropagation()

      if (this.confirmCallback) {
        this.confirmCallback()
      }

      this.$emit('cancel', false)
      this.$emit('update:is-show', false)
    }
  }
}
</script>
