<template>
  <v-select
    ref="selectRef"
    class="rcc-select"
    :items="items"
    :label="label"
    :disabled="isDisabled"
    :item-text="showBy"
    :rules="rules"
    :no-data-text="noDataText"
    v-model="selectedValue"
    :clearable="clearable"
    return-object
    dense
    outlined
    v-bind="$attrs"
    :menu-props="{
      contentClass: menuClass
    }"
  >
    <template v-for="slotName in Object.keys($scopedSlots)"
      v-slot:[slotName]="slotScope">
      <slot :name="slotName" v-bind="slotScope" />
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'RccSelect',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: ''
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: null
    },

    items: {
      type: Array,
      default: () => []
    },

    itemText: {
      type: String,
      default: 'name'
    },

    value: {
      type: [String, Number, Object, Array],
      default: ''
    },

    chooseBy: {
      type: String,
      default: 'id'
    },

    showBy: {
      type: String,
      default: 'value'
    },

    rules: {
      type: Array,
      default: () => []
    },

    clearable: {
      type: Boolean,
      default: false
    },

    noDataText: {
      type: String,
      default: 'Нет доступных опций'
    },

    returnObject: {
      type: Boolean,
      default: false
    },

    listAttach: {
      type: Boolean,
      default: false
    },

    menuClass: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      isMounted: false
    }
  },

  computed: {
    selectedValue: {
      get() {
        if (this.returnObject) {
          return this.value
        }

        return this.items.find(item => '' + (item && item[this.chooseBy]) === '' + this.value)
      },

      set(value) {
        if (this.returnObject) {
          this.$emit('input', value)
          return
        }

        this.$emit('input', value && value[this.chooseBy])
      }
    },

    attach() {
      return this.listAttach && this.isMounted ? this.$refs?.selectRef?.$el : false
    }
  },

  mounted() {
    if (this.width) {
      this.$refs.selectRef.$el.style.width = this.width
    }

    this.isMounted = true
  }
}
</script>

<style lang="scss">
.rcc-select {
  .v-input__slot {
    background: $text-input-bg !important;
  }

  fieldset {
    border-color: $text-input-border-color;
  }

  &:hover {
    fieldset {
      border-color: $text-input-hovered-border-color;
    }
  }

  .v-label, .v-select__selection, .v-list-item__title {
    font-size: $base-font-size;
  }

  &.v-input--is-disabled {
    fieldset {
      border-color: $text-input-disabled-border-color;
    }

    &:hover {
      fieldset {
        border-color: $text-input-disabled-border-color;
      }
    }
  }

  .v-menu__content {
    max-width: unset;
  }
}
</style>
