import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":_vm.maxWidth},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._v(_vm._s(_vm.text))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.handleConfirmClick}},[_vm._v(" "+_vm._s(_vm.confirmText)+" ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.handleCancelClick}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }