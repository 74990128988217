<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    class="base-autocomplete"
  >
    <template v-for="slotName in Object.keys($scopedSlots)"
      v-slot:[slotName]="slotScope">
      <slot :name="slotName" v-bind="slotScope" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'RccBaseAutocomplete',

  inheritAttrs: false
}
</script>

<style lang="scss" scoped>
.base-autocomplete {
  ::v-deep .v-input__slot {
    background: $text-input-bg;
  }

  ::v-deep fieldset {
    border-color: $text-input-border-color;
  }

  &:hover {
    ::v-deep fieldset {
      border-color: $text-input-hovered-border-color;
    }
  }

  ::v-deep .v-select__selections {
    padding: 6px 0 !important;
  }

  ::v-deep .v-label, .v-select__selection, .v-list-item__title {
    font-size: $base-font-size;
  }

  &.v-input--is-disabled {
    ::v-deep fieldset {
      border-color: $text-input-disabled-border-color;
    }

    &:hover {
      ::v-deep fieldset {
        border-color: $text-input-disabled-border-color;
      }
    }
  }
}
</style>
