<template>
  <div class="rcc-checkboxes-group">
    <div
      class="rcc-checkboxes-group__column"
      v-for="(option, index) in options"
      :key="index"
    >
      <rcc-checkbox
        :label="option[showBy]"
        :color="option.color"
        :inputValue="option[chooseBy]"
        v-model="selected"
      />
    </div>
  </div>
</template>

<script>
import rccCheckbox from './rcc-checkbox'

export default {
  name: 'rccCheckboxesGroup',

  components: { rccCheckbox },

  props: {
    value: {
      type: Array,
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    chooseBy: {
      type: String,
      default: 'value'
    },

    showBy: {
      type: String,
      default: 'label'
    }
  },

  computed: {
    selected: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-checkboxes-group {
  display: flex;
  flex-wrap: wrap;

  &__column {
    padding-right: 20px;
  }
}
</style>
