<template>
  <rcc-base-autocomplete
    v-model="selectedValue"
    v-bind="$attrs"
    ref="autocompleteRef"
    :items="items"
    dense
    outlined
    :no-data-text="noDataText"
    :item-text="showBy"
    class="autocomplete"
    return-object
  />
</template>

<script>
import RccBaseAutocomplete from './base-autocomplete'

export default {
  name: 'RccAutocomplete',

  inheritAttrs: false,

  components: {
    RccBaseAutocomplete
  },

  props: {
    value: {
      type: [String, Number, Object],
      default: ''
    },

    items: {
      type: Array,
      default: () => []
    },

    returnObject: {
      type: Boolean,
      default: false
    },

    noDataText: {
      type: String,
      default: 'Нет доступных опций'
    },

    showBy: {
      type: String,
      default: 'value'
    },

    chooseBy: {
      type: String,
      default: 'id'
    },

    width: {
      type: String,
      default: null
    }
  },

  computed: {
    selectedValue: {
      get() {
        if (this.returnObject) {
          return this.value
        }

        return this.items.find(item => '' + (item && item[this.chooseBy]) === '' + this.value)
      },

      set(value) {
        if (this.returnObject) {
          this.$emit('input', value)
        }

        this.$emit('input', value && value[this.chooseBy])
      }
    }
  },

  mounted() {
    if (this.width) {
      this.$refs.autocompleteRef.$el.style.width = this.width
    }
  }
}
</script>
